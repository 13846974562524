import { lazy } from "react";
export function isThisDecimal(val) {
    if (val?.indexOf(".") !== -1) {
        return true; // decimal 
    }
    return false; // number
}

export function blankCheckFn(...args){
    for (const arg of args) {
        if (arg == undefined || arg == null || arg === "") {
            return true;
        }
    }
    return false;
}

export function isAnyOneNonBlank(...args) {
    for (const arg of args) {
        if (!blankCheckFn(arg) && !isNaN(arg)) {
            return true;
        }
    }
    return false;
}

export function isDropdownsAll(...args){
    for (arg in agrs) {
        if (args !== "All") {
            return false;
        }
    }
    return true;
}

export function objectHasAllBlankValues(obj) {
    if (obj) {
        return Object.values(obj).every(value => {
            return (value === null || value === undefined || value === '')
        });
    }
}


export function selectWeek(date) {
    let selectWeek = [];
    for (let i = 1; i < 6; i++) {
        let weekday = new Date(date) // clone the selected date, so we don't mutate it accidentally.
        let selectedWeekdayIndex = weekday.getDay() // i.e. 5 for friday
        let selectedDay = weekday.getDate() // 1-31, depending on the date
        weekday.setDate(selectedDay - selectedWeekdayIndex + i)
        selectWeek = [...selectWeek, weekday]
    }
    return selectWeek;
}

export function preventSubmitOnEnter(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
        keyEvent.preventDefault();
    }
}

export function arrayDifference(arr1, arr2) {
    const difference = [];

    for (let i = 0; i < arr1.length; i++) {
        if (arr2.indexOf(arr1[i]) === -1) {
            difference.push(arr1[i]);
        }
    }

    return difference;
} 

export function fetchPropertyBeginsWith(data, property) {
    const filteredData = [];
    for (var key in data) {
        if (key.startsWith(property)) {
            filteredData.push(data[key]);
        }
    }
    return filteredData;
}

export function createPercentage(value, fixedPositions = 2) {
    return (!blankCheckFn(value) && !isNaN(parseFloat(value))) ? parseFloat(value).toFixed(fixedPositions) + "%" : value; 
}

export function calculateIncreaseOrDecrease(value) {
    if (value?.toString().trim().slice(-1) == "%") value = value?.toString().trim().slice(0, -1);
    return parseFloat(value) > 0 ? "Increase %" : (parseFloat(value) == 0 || value == "" || value == null) ? "Adjustment %" : "Decrease %";
}

export const lazyWithRetry = (componentImport) =>
    lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem(
                'page-has-been-force-refreshed'
            ) || 'false'
        );

        try {
            const component = await componentImport();

            window.localStorage.setItem(
                'page-has-been-force-refreshed',
                'false'
            );

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                // Assuming that the user is not on the latest version of the application.
                // Let's refresh the page immediately.
                window.localStorage.setItem(
                    'page-has-been-force-refreshed',
                    'true'
                );
                if (/Loading chunk [\d]+ failed/.test(error.message)) {
                    return window.location.reload();
                }
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            console.error("Uncaught ",error);
            if (/Loading chunk [\d]+ failed/.test(error.message)) {
                return import("../dcpricingtool/Components/Pricing404");
            }
            //throw error;
        }
    });

export const compress = ([head, ...rest]) => {
    if (!head) return [];
    const tail = compress(rest);
    return head === tail[0] ? tail : [head, ...tail];
}